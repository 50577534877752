// eslint-disable
import '@webcomponents/custom-elements';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ResizeObserver from 'resize-observer-polyfill';
window.ResizeObserver = ResizeObserver;

// eslint-disable-next-line no-prototype-builtins
if (!Object.hasOwnProperty('getOwnPropertyDescriptors')) {
  Object.defineProperty(Object, 'getOwnPropertyDescriptors', {
    configurable: true,
    writable: true,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: function getOwnPropertyDescriptors(object: any) {
      return Reflect.ownKeys(object).reduce((descriptors, key) => {
        return Object.defineProperty(descriptors, key, {
          configurable: true,
          enumerable: true,
          writable: true,
          value: Object.getOwnPropertyDescriptor(object, key),
        });
      }, {});
    },
  });
}

// Polyfill DOM append
(function (arr) {
  arr.forEach(function (item) {
    // eslint-disable-next-line no-prototype-builtins
    if (item.hasOwnProperty('append')) {
      return;
    }
    Object.defineProperty(item, 'append', {
      configurable: true,
      enumerable: true,
      writable: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: function append(args: any) {
        const argArr = Array.prototype.slice.call(args),
          docFrag = document.createDocumentFragment();

        argArr.forEach(function (argItem) {
          const isNode = argItem instanceof Node;
          docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
        });

        this.appendChild(docFrag);
      },
    });
  });
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);
